import React from 'react';
import styles from "./ThemeProvider.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import CodeString from "./CodeString";
import ComponentsToRender from "./ComponentsToRender";
import apiData from "./apiData";

import CarbonAds from "../../../CarbonAds";

const ThemeProvider = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">Theme Provider</h1>

			<DocBlock
				sectionName="default"
				subTitle="Customize your app with the color you want is really easy using the ThemeProvider component."
				codeString={ CodeString }>
				<div style={{ marginTop: "3rem" }}>
					<ComponentsToRender />
				</div>
			</DocBlock>

			<div style={{ marginBottom: "40px" }}>
				<p>For a tutorial on how to customize your app using <code>ThemeProvider</code>, visit <a href="https://medium.com/flawwwless-ui/customize-your-flawwwless-ui-theme-c1a4552aece0" target="_blank" rel="noopenner noreferrer">this blog post</a>!</p>
			</div>

			<ApiTable
				apiData={ apiData } />
		</div>
	)
}

export default ThemeProvider;
