import React from 'react';
import styles from "./Layout.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import { codeString1, codeString2, codeString3, codeString4, codeString5 } from "./CodeString";
import { ComponentsToRender1, ComponentsToRender2, ComponentsToRender3, ComponentsToRender4 } from "./ComponentsToRender";
import { apiContainerData, apiColData } from "./apiData";
import { Icon, Table } from "flwww";
import CarbonAds from "../../../CarbonAds";

const Layout = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />
			
			<h1 className="componentTitle">Layout</h1>

			<DocBlock
				sectionName="default"
				subTitle="12 Grids System.">
				<ComponentsToRender1 />
			</DocBlock>


			<h1 className="componentTitle">Container</h1>

			<DocBlock
				sectionName="container"
				subTitle="In Flawwless ui, Container are required when using our default grid system. As default, a Container is fixed-width responsive changing at specific breakpoints."
				codeString={ codeString1 }>
				<div></div>
			</DocBlock>

			<DocBlock
				sectionName="container"
				subTitle={ <span>A Container can also be full width using the <code>full</code> props.</span>}
				codeString={ codeString2 }>
				<div></div>
			</DocBlock>


			<h1 className="componentTitle">Grid</h1>

			<h3>Container</h3>
			<DocBlock
				sectionName="grid"
				subTitle={ gridExplanation }
				codeString={ codeString3 }>
				<ComponentsToRender2 />
			</DocBlock>


			<h1 className="componentTitle">Offset</h1>

			<DocBlock
				sectionName="offset"
				subTitle={ <span>To move columns to the right, you need to use <code>offset-*</code> inside the <code>grid</code> prop. This allows you to add a left margin of a <code>Col</code> component by * columns. (i.e. <code>grid="offset-3 6"</code> moves the <code>Col</code> over 3 columns width.)</span> }
				codeString={ codeString4 }>
				<ComponentsToRender3 />
			</DocBlock>


			<h1 className="componentTitle">Breakpoints</h1>

			<DocBlock
				sectionName="breakpoints"
				subTitle={ breakpointsExplanation }
				codeString={ codeString5 }>
				<ComponentsToRender4 />
			</DocBlock>

			<h1 className="componentTitle">API</h1>

			<h3>Container</h3>
			<ApiTable
				noTitle={ true }
				apiData={ apiContainerData } />

			<h3>Row</h3>
			<p><code>Row</code> component doesn't take any props.</p>

			<h3>Col</h3>
			<ApiTable
				noTitle={ true }
				apiData={ apiColData } />
		</div>
	)
}

export default Layout;


const gridExplanation = (
	<span>
		<span>If you follow these simple rules, Flawwwless layouts should be work great for you:</span>
		<ol className={ styles.customThemeList } style={{ "marginTop": "2rem" }}>
			<li>
				<p>At first, you should place your content inside the <code>Col</code> and the <code>Col</code> should be placed inside a <code>Row</code> component. Finally, your <code>Row</code> component should be wrapped inside a <code>Container</code> component. (e.g. <code>Container</code><Icon type="arrowForward" /><code>Row</code><Icon type="arrowForward" /><code>Col</code>)</p>
			</li>
			<li>
				<p>The column grid system is a value between <code>1</code> and <code>12</code>. If you want to use 2 columns of equal width, you can create it using 2 columns with <code>"6"</code> as the grid value. (i.e. <code>grid="6"</code>)</p>
			</li>
	    <li>
				<p>Because Flawwwless is using a 12 grids system, if the sum of the <code>Col</code> exceed <code>12</code>, the overflowing <code>Col</code> will start on a new line.</p>
			</li>
		</ol>
	</span>
);


const breakpointsExplanation = (
	<span>
		<span>If you follow these simple rules, Flawwwless layouts should be work great for you:</span>

		<div style={{ padding: "0.1rem 2.5rem 1.5rem" }}>
			<p>xl : <code>≥1200px</code></p>
			<p>lg : <code>≥992px</code></p>
			<p>md : <code>≥768px</code></p>
			<p>sm : <code>≥576px</code></p>
		</div>

		<p>For example, if you want to use a <code>Col</code> that take 50% of the width on devices that are <code>≥768px</code>, you should use <code>grid="md-6"</code>. You can add multiple <code>Col</code> size for multiples breakpoints by separating them with space (i.e. <code>grid="sm-8 md-6 lg-5 xl-4"</code>). You can also use breakpoints while using offset (e.g. <code>grid="offset-md-3"</code>).</p>

	  <p>The breakpoints of responsive grid follow <a href="https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints" target="_blank" rel="noopener noreferrer">BootStrap 4 media queries rules</a>.</p>
	</span>
);
