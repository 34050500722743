import React from 'react';
import styles from "./Button.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";

import { DefaultBtnsToRender, RoundedBtnsToRender, OutlinedBtnsToRender, LoadingBtnsToRender } from "./ComponentsToRender";
import { defaultButtons, roundedButtons, outlinedButtons } from "./CodeString";

import apiData from "./apiData";

import CarbonAds from "../../../CarbonAds";


const Buttons = (props) => {

	return (
		<div className="fadeIn">
			<CarbonAds />
			<h1 className="componentTitle">Button</h1>

			<DocBlock
				sectionName="default"
				subTitle="Button types."
				codeString={ defaultButtons }>
				<DefaultBtnsToRender />
			</DocBlock>

			<DocBlock
				sectionName="rounded"
				subTitle="Rounded buttons."
				codeString={ roundedButtons }>
				<RoundedBtnsToRender />
			</DocBlock>

			<DocBlock
				sectionName="outlined"
				subTitle="Outlined buttons."
				codeString={ outlinedButtons }>
				<OutlinedBtnsToRender />
			</DocBlock>

			<DocBlock
				sectionName="loading"
				subTitle="Loading buttons."
				codeString={ defaultButtons }>
				<LoadingBtnsToRender />
			</DocBlock>

			<ApiTable
				apiData={ apiData } />

		</div>
	)
}

export default Buttons;
