const defaultButtons = `import React from "react";
import { Button } from "flwww";

const MyComponent = () => (
  <div>
    <Button onClick={ () => alert("Button clicked!") }>Default</Button>

    <Button type="primary">Primary</Button>

    <Button type="success">Success</Button>

    <Button type="danger">Danger</Button>

    <Button colors={{ mainColor: "#e056fd", secondColor: "#fff" }}>Custom</Button>
  </div>
);

export default MyComponent`;

const roundedButtons = `import React from "react";
import { Button } from "flwww";

const MyComponent = () => (
  <div>
    <Button onClick={ () => alert("Button clicked!") } round>Default</Button>

    <Button type="primary" round>Primary</Button>

    <Button type="success" round>Success</Button>

    <Button type="danger" round>Danger</Button>

    <Button round colors={{ mainColor: "#55E6C1", secondColor: "#000" }}>Custom</Button>
  </div>
);

export default MyComponent`;

const outlinedButtons = `import React from "react";
import { Button } from "flwww";

const MyComponent = () => (
  <div>
    <Button outlined>Default</Button>
		
    <Button type="primary" outlined>Primary</Button>
		
    <Button type="success" outlined round >Success</Button>
		
    <Button type="danger" outlined round>Danger</Button>
		
    <Button outlined round colors={{ mainColor: "#000", secondColor: "#fff" }}>Custom</Button>
  </div>
);

export default MyComponent`;

export { defaultButtons, roundedButtons, outlinedButtons };
