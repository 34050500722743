import React, { Component } from "react";
import { Modal, Button } from "flwww";

const buttonStyle = {
	marginRight: "10px",
	marginBottom: "10px",
}

class ComponentsToRender extends Component {
  state = {
    modal1IsVisible: false,
		modal2IsVisible: false,
  }

  toggleModal1 = () => {
    // This function change the state of the modal visibility (e.g. this.state.modalIsVisible)
    this.setState(prevState => ({ modal1IsVisible: !prevState.modal1IsVisible }));
  }

	toggleModal2 = () => {
    // This function change the state of the modal visibility (e.g. this.state.modalIsVisible)
    this.setState(prevState => ({ modal2IsVisible: !prevState.modal2IsVisible }));
  }

  render(){
    const { modal1IsVisible, modal2IsVisible } = this.state;

    return (
      <div>
        <Button
					style={ buttonStyle }
					type="primary" onClick={ this.toggleModal1 }>Show the modal</Button>

				<Button
				  style={ buttonStyle }
				  type="primary"
				  onClick={ this.toggleModal2 }>Modal with title</Button>

        <Modal
          isVisible={ modal1IsVisible }
          toggleModal={ this.toggleModal1 }>

					<h3>This is a modal created simply!</h3>

					<p>You only have to put JSX code inside the Modal component!</p>
					<p>To control the visibility of the Modal, you have to change the isVisible props.</p><p>To close the Modal, you need to pass a function that change the state that control the visibility of the modal (e.g. toggle Modal function).</p>

					<div style={{ marginTop: "25px" }}>
					  <Button
						  onClick={ this.toggleModal1 }
						  type="primary">Continue</Button>
					</div>

        </Modal>

				<Modal
          isVisible={ modal2IsVisible }
          toggleModal={ this.toggleModal2 }
					title="This is a modal with a title!">

					<p>You only have to put JSX code inside the Modal component!</p>
					<p>To control the visibility of the Modal, you have to change the isVisible props.</p><p>To close the Modal, you need to pass a function that change the state that control the visibility of the modal (e.g. toggle Modal function).</p>

					<div style={{ marginTop: "25px" }}>
					  <Button
						  onClick={ this.toggleModal2 }
						  type="primary">Continue</Button>
					</div>

        </Modal>
      </div>
    )
  }
}

export default ComponentsToRender;
