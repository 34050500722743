export default `import React, { Component } from "react";
import { Modal, Button } from "flwww";

class MyComponent extends Component {
  state = {
    modalIsVisible: false  // This control if the modal is visible or not
  }

  toggleModal = () => {
    // This function change the state of the modal visibility (e.g. this.state.modalIsVisible)
    this.setState(prevState => ({ modalIsVisible: !prevState.modalIsVisible }));
  }

  render(){
    const { modalIsVisible } = this.state;

    return (
      <div>
        <Button type="primary" onClick={ this.toggleModal }>Show the modal</Button>

        <Modal
          isVisible={ modalIsVisible }
          toggleModal={ this.toggleModal }>

          <h3>Put anything you want inside!</h3>

          <Button onClick={ this.toggleModal } type="primary">Continue</Button>

        </Modal>
      </div>
    )
  }
}

export default MyComponent;`;
