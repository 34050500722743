import React, { Component } from "react";
import { TopLoader, Button } from "flwww";

class ComponentsToRender1 extends Component {
	state = {
		loading: false,
	}

	fakeLoad = () => {
		this.setState({ loading: true });
		const timeout = setTimeout(() => {
			this.setState({ loading: false });
		}, 2000 + Math.random() * 1500);
	}

	render(){
		return(
			<div>
				<Button
					type="primary"
					disabled={ this.state.loading }
					loading={ this.state.loading }
					onClick={ this.fakeLoad }>Click to load</Button>
				<TopLoader
					loading={ this.state.loading } />
			</div>
		)
	}
}


export { ComponentsToRender1 };
