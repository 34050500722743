import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import styles from "./Navbar.module.scss";
import Sun from "../../images/SVG/Sun.svg";
import SunIcon from "./SunIcon";
import { Container, Icon, Drawer } from "flwww";
import packageJSON from "../../../package.json";
import ComponentsMenu from "../Documentation/ComponentsMenu";

const flwwwVersion = packageJSON.dependencies.flwww.slice(1);


class Navbar extends Component {
	state = {
		isMobile: window.innerWidth < 768,
		drawerIsVisible: false,
	}

	toggleDrawer = () => {
    this.setState(prevState => ({ drawerIsVisible: !prevState.drawerIsVisible }))
  }

	componentDidMount() {
  	this.updateWindowDimensions();
  	window.addEventListener('resize', this.updateWindowDimensions);
		// Close the modal on path change
		this.unlisten = this.props.history.listen((location, action) => {
			this.setState({ drawerIsVisible: false });
    });
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.isMobile === true && this.state.isMobile === false){
			this.setState({ drawerIsVisible: false });
		}
	}

  componentWillUnmount() {
      this.unlisten();
  }

	updateWindowDimensions = () => {
	  this.setState({ isMobile: window.innerWidth < 768 });
	}

	render(){
		const { textColor } = this.props;
		const { isMobile } = this.state;

		return (
			<div className={ styles.navbarWrapper }>
			  <Container full>
					<div className={ styles.innerNavbar }>
						<Link to="/"
						  style={{ color: textColor }}
						  className={ styles.navLogo }>
							Flawwwless ui<sup className={ styles.flawVersion }>{ flwwwVersion }</sup>
						</Link>

						<div className={ styles.rightSideNav }>
							{
								!isMobile && <Link to="/documentation/intro">Components</Link>
							}


							{/*<button
								style={{ marginLeft: "20px" }}
								className="transparentBtn"
								onClick={ this.props.handleThemeChange }>
								<span className={ styles.sunIcon }>
									<SunIcon
										color={ "#006EFF" }
										size={ "30px" } />
								</span>
							</button>*/}

							{
								isMobile && (
									<button
										style={{ marginLeft: "20px" }}
										className="transparentBtn"
										onClick={ this.toggleDrawer }>
										<span className={ styles.mobileMenuBtn }>
											<Icon
												type="menu"
												color={ "#006EFF" }
												size={ "35px" } />
										</span>
									</button>
								)
							}

						</div>
					</div>
				</Container>

				{
					isMobile && (
						<Drawer
		          showDrawer={ this.state.drawerIsVisible }
		          toggleDrawer={ this.toggleDrawer }>
		          <div>
								<div
									style={{ marginTop: "-20px", marginBottom: "20px" }}
									className={ styles.navLogo }>Flawwwless ui</div>
								<ComponentsMenu />
							</div>
		        </Drawer>
					)
				}
			</div>
		)
	}
}

export default withRouter(Navbar);
