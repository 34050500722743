import React from 'react';
import styles from "./Card.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import { codeString1, codeString2 } from "./CodeString";
import { ComponentsToRender1, ComponentsToRender2 } from "./ComponentsToRender";
import apiData from "./apiData";

import CarbonAds from "../../../CarbonAds";

const Card = (props) => {

	return (
		<div className="fadeIn">
			<CarbonAds />
			
			<h1 className="componentTitle">Card</h1>

				<DocBlock
					sectionName="default"
					subTitle="Simple rectangular cards."
					codeString={ codeString1 }>
					<ComponentsToRender1 />
				</DocBlock>

				<DocBlock
					sectionName="no-title"
					subTitle="No title card customized."
					codeString={ codeString2 }>
					<ComponentsToRender2 />
				</DocBlock>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default Card;
