import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "content",
		Description: "	Simply the content of the message.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "type",
		Description: "Specify the type of the message.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "duration",
		Description: "Specify the duration of the message.",
		Type: "Number",
		Default: "2.5",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
