import React, { Component } from 'react';
import './App.css';
import { Switch, Route }from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Homepage from "./components/Homepage";
import Documentation from "./components/Documentation";
import ErrorPage from "./components/ErrorPage";



class App extends Component {
	state = {
		isDark: false,
	}

	handleThemeChange = () => {
		this.setState( prevState => ({
			isDark: !prevState.isDark,
		}))
	}

	render(){
		const { isDark } = this.state;
		const textColor = isDark ? "#fff" : "#000";

		return (
	    <div
				className="fadeIn"
				style={{ transition: ".3s", backgroundColor: isDark ? "#212121" : "#fff" }}>
	      <Navbar
					textColor={ textColor }
					handleThemeChange={ this.handleThemeChange } />

				<div style={{ marginTop: "57px" }}>
					<Switch>
						<Route
							exact path="/"
							render={ () => (
								<Homepage
									textColor={ textColor } />
							) } />
						<Route
							path="/documentation"
							render={ () => (
								<Documentation
									isDark={ isDark }
									textColor={ textColor } />
							) } />

						<Route component={ ErrorPage } />
					</Switch>
				</div>

				<Footer />
	    </div>
	  );
	}
}

export default App;
