import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "img",
		Description: "The address of the image you want to show.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "title",
		Description: "This is the title of the article.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "category",
		Description: "This is the category of the article.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "author",
		Description: "This is the author of the article.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "time",
		Description: "This is the time of the article.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "titleClassName",
		Description: "Add a className to the title to customize (i.e. to change font-size on different device width).",
		Type: "String",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
