export default `import React from "react"
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { ThemeProvider } from "flwww";

const theme = {
  defaultColor: "#079992",
  defaultTextColor: "#262626",
  primaryColor: "#38ada9",
  primaryTextColor: "#262626",
  successColor: "#78e08f",
  successTextColor: "#262626",
  dangerColor: "#b71540",
  dangerTextColor: "#262626",
};

ReactDOM.render(
  <ThemeProvider theme={ theme }>
    <App />
  </ThemeProvider>
, document.getElementById('root'));`;
