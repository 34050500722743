const codeString1 = `import React, { Component } from "react";
import { TopLoader, Button } from "flwww";

class MyComponent extends Component {
	state = {
		loading: false,
	}

	fakeLoad = () => {
		// Change the state to loading
		this.setState({ loading: true });
		// Loading while making a fake api call with timeout (between 2 to 3 sec.)
		const timeout = setTimeout(() => {
			this.setState({ loading: false });
		}, 2000 + Math.random() * 1500);
	}

	render(){
		return(
			<div>
				<Button
					type="primary"
					disabled={ this.state.loading }
					loading={ this.state.loading }
					onClick={ this.fakeLoad }>Click to load</Button>
				<TopLoader
					loading={ this.state.loading } />
			</div>
		)
	}
};`;


export { codeString1 };
