import React from 'react';
import styles from "./Documentation.module.scss";
import { Switch, Route } from "react-router-dom";
import { Container } from "flwww";

import ComponentsMenu from "./ComponentsMenu";
import Intro from "./Components/Intro";
import Button from "./Components/Button";
import Modal from "./Components/Modal";
import Message from "./Components/Message";
import Icon from "./Components/FlwwwIcon";
import Menu from "./Components/Menu";
import Drawer from "./Components/Drawer";
import Table from "./Components/Table";
import Card from "./Components/Card";
import Input from "./Components/Input";
import Layout from "./Components/Layout";
import Checkbox from "./Components/Checkbox";
import Progress from "./Components/Progress";
import RadialProgress from "./Components/RadialProgress";
import Tooltip from "./Components/Tooltip";
import ThemeProvider from "./Components/ThemeProvider";
import Rating from "./Components/Rating";
import Tag from "./Components/Tag";
import TopLoader from "./Components/TopLoader";
import Avatar from "./Components/Avatar";
import Badge from "./Components/Badge";
import Dropdown from "./Components/Dropdown";
import ArticleCard from "./Components/ArticleCard";
import Statistic from "./Components/Statistic";
import SwitchComponent from "./Components/Switch";


import ErrorPage from "../ErrorPage";

import ScrollTop from "../ScrollTop";

const Documentation = (props) => {

	const borderColor = props.isDark ? "#e8e8e8" : "#0002";


	return (
		<Container full>
			<div
				style={{ color: props.textColor }}
				className={ styles.documentationAndMenu }>

				<div className={ styles.desktopMenu }>
					<ComponentsMenu
						isDark={ props.isDark } />
				</div>

			  <div
					id="scrollableDoc"
					style={{ borderColor }}
					className={ styles.documentationWrapper }>

					<ScrollTop>
						<Switch>

							<Route path="/documentation/intro" component={ Intro }  />
							<Route path="/documentation/button" component={ Button }  />
							<Route path="/documentation/modal" component={ Modal }  />
							<Route path="/documentation/message" component={ Message }  />
							<Route path="/documentation/icon" component={ Icon }  />
							<Route path="/documentation/menu" component={ Menu }  />
							<Route path="/documentation/drawer" component={ Drawer }  />
							<Route path="/documentation/table" component={ Table }  />
							<Route path="/documentation/card" component={ Card }  />
							<Route path="/documentation/input" component={ Input }  />
							<Route path="/documentation/layout" component={ Layout }  />
							<Route path="/documentation/theme-provider" component={ ThemeProvider }  />


							<Route path="/documentation/checkbox" component={ Checkbox }  />
						  <Route path="/documentation/progress" component={ Progress }  />
							<Route path="/documentation/radial-progress" component={ RadialProgress }  />
						  <Route path="/documentation/tooltip" component={ Tooltip }  />
							<Route path="/documentation/rating" component={ Rating }  />
						  <Route path="/documentation/tag" component={ Tag }  />
							<Route path="/documentation/top-loader" component={ TopLoader } />
						  <Route path="/documentation/avatar" component={ Avatar } />
							<Route path="/documentation/badge" component={ Badge } />
							<Route path="/documentation/dropdown" component={ Dropdown } />
							<Route path="/documentation/article-card" component={ ArticleCard } />

							<Route path="/documentation/statistic" component={ Statistic } />

						  <Route path="/documentation/switch" component={ SwitchComponent } />

							<Route component={ ErrorPage } />

						</Switch>
					</ScrollTop>

				</div>
			</div>
		</Container>
	)
}

export default Documentation;
