import React from 'react';
import styles from "./ApiTable.module.scss";
import { Table } from "flwww";

const ApiTable = (props) => {

	const { columns, rows } = props.apiData;

	const formatedRows = rows.map(data => ({
			Property: <span className={ styles.tableProperty }>{ data.Property }</span>,
			Description: data.Description,
			Type: <span className={ styles.tableType }>{ data.Type }</span>,
			Default: <code>{ data.Default }</code>
	}));

	return (
		<div id="api" style={{ marginBottom: "2rem" }}>
		  {
				!props.noTitle && (
					<h3 className={ styles.apiTitle }>API</h3>
				)
			}
			<Table
			  bordered
				columns={ columns }
				rows={ formatedRows } />
		</div>
	)
}

export default ApiTable;
