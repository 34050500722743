import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "src",
		Description: "The address of the image you want to show.",
		Type: "String",
		Default: `""`,
	},
	{
		Property: "size",
		Description: "Define the size of the Avatar.",
		Type: "String",
		Default: `"40px"`,
	},
	{
		Property: "initials",
		Description: <span>If there is no <code>src</code> props, the Avatar will how the initials. The initials scale automatically with the size of the Avatar. If the initials have a <code>length</code> of more than 5 characters, the Avatar will only show the first 5.</span>,
		Type: "String",
		Default: `""`,
	},
	{
		Property: "style",
		Description: "Override the Avatar styling.",
		Type: "Object",
		Default: "{}",
	},
	{
		Property: "className",
		Description: "Add a className to the Avatar.",
		Type: "String",
		Default: `""`,
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
