import React from 'react';
import styles from "./FlwwwIcon.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import CodeString from "./CodeString";
import ComponentsToRender from "./ComponentsToRender";
import apiData from "./apiData";
import { Icon } from "flwww";
import IconSearch from "./IconSearch";
import CarbonAds from "../../../CarbonAds";



const FlwwwIcon = (props) => {


	return (
		<div className="fadeIn">

			<CarbonAds />

			<a href="https://www.producthunt.com/posts/flawwwless-ui-icons?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flawwwless-ui-icons" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=166271&theme=dark" alt="Flawwwless UI Icons - 250+ Open-source React.js icons | Product Hunt Embed" style={{ width: "250px", height: "54px", margin: "50px 0" }} width="250px" height="54px" /></a>

			<h1 className="componentTitle">Icon</h1>

	    <IconSearch />

			<DocBlock
				sectionName="default"
				subTitle="Some examples using props."
				codeString={ CodeString }>
				<ComponentsToRender />
			</DocBlock>

			<ApiTable
				apiData={ apiData } />
		</div>
	)
}

export default FlwwwIcon;
