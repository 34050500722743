import React, { Component } from "react";
import { Tag } from "flwww";

class ComponentsToRender1 extends Component {

	render(){

		return(
			<div>
			  <Tag>Tag 1</Tag>
				<Tag>Tag 2</Tag>
				<Tag>Tag 3</Tag>
			</div>
		)
	}
}


class ComponentsToRender2 extends Component {

	render(){

		return(
			<div>
				<Tag color="#ff2c2b">#ff2c2b</Tag>
				<Tag color="#01b875">#01b875</Tag>
				<Tag color="#8956ff">#8956ff</Tag>
				<Tag
					style={{ borderRadius: "12px" }}
					color="#00bcd4">#00bcd4</Tag>
			</div>
		)
	}
}

export { ComponentsToRender1, ComponentsToRender2 };
