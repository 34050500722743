import React from 'react';
import styles from "./Menu.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import CodeString from "./CodeString";
import ComponentsToRender from "./ComponentsToRender";
import apiData from "./apiData";

import CarbonAds from "../../../CarbonAds";

const Menu = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />
			
			<h1 className="componentTitle">Menu</h1>

				<DocBlock
					sectionName="default"
					subTitle="An easy and elegant way to navigate throught your app."
					codeString={ CodeString }>
					<ComponentsToRender />
				</DocBlock>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default Menu;
