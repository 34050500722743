import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "count",
		Description: "Show the notification count.",
		Type: "Number",
		Default: `undefined`,
	},
	{
		Property: "dot",
		Description: "Whether the user have a notification or not.",
		Type: "Boolean",
		Default: `undefined`,
	},
	{
		Property: "text",
		Description: "What text you want to show in the badge.",
		Type: "String",
		Default: `""`,
	},
	{
		Property: "style",
		Description: "Override the Badge styling.",
		Type: "Object",
		Default: "{}",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
