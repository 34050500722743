import React from 'react';
import styles from "./Input.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import { codeString1, codeString2, codeString3, codeString4, codeString5 } from "./CodeString";
import { ComponentsToRender1, ComponentsToRender2, ComponentsToRender3, ComponentsToRender4, ComponentsToRender5 } from "./ComponentsToRender";
import apiData from "./apiData";
import CarbonAds from "../../../CarbonAds";


const Input = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">Input</h1>

			<DocBlock
				sectionName="default"
				subTitle="Get simple input for your user that just work like normal inputs."
				codeString={ codeString1 }>
				<ComponentsToRender1 />
			</DocBlock>

			<DocBlock
				sectionName="with-icon"
				subTitle="You can simply add any icons you want."
				codeString={ codeString2 }>
				<ComponentsToRender2 />
			</DocBlock>

			<DocBlock
				sectionName="error-handling"
				subTitle="Simplifying errors handling inside forms."
				codeString={ codeString3 }>
				<ComponentsToRender3 />
			</DocBlock>

			<DocBlock
				sectionName="input-list"
				subTitle="All the input type you need!"
				codeString={ codeString4 }>
				<ComponentsToRender4 />
			</DocBlock>

			<DocBlock
				sectionName="customization"
				subTitle="Style your input as wanted!"
				codeString={ codeString5 }>
				<ComponentsToRender5 />
			</DocBlock>

			<ApiTable
				apiData={ apiData } />
		</div>
	)
}

export default Input;
