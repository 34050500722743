import React from "react";
import { Button, message } from "flwww";

const ComponentsToRender = () => (
  <div>
    <Button
			style={{ marginRight: "10px", marginBottom: "10px" }}
      onClick={ () => message("This is a regular message.")}
      type="primary">Regular message</Button>

    <Button
			style={{ marginRight: "10px", marginBottom: "10px" }}
      onClick={ () => message("This is a success message.", "success") }
      type="success">Success message</Button>

    <Button
			style={{ marginRight: "10px", marginBottom: "10px" }}
      onClick={ () => message("This is an error message.", "error") }
      type="danger">Error message</Button>

    <Button
			style={{ marginRight: "10px", marginBottom: "10px" }}
      onClick={ () => message("This is a question message.", "question") }>Question message</Button>

    <Button
			style={{ marginRight: "10px", marginBottom: "10px" }}
      onClick={ () => message("This is a message without icon.", "noIcon") }>Message without icon</Button>
  </div>
);

export default ComponentsToRender
