import React, { Component } from 'react';
import {
	Card,
	Button,
	Col,
	Input,
	Avatar,
	Statistic,
	ArticleCard,
	Rating,
	Badge,
	RadialProgress,
} from "flwww";
import styles from "./RollingFeatures.module.scss";

class FeaturesList extends Component {

	render(){
		return (
			<div>
				<Card style={{ marginBottom: "20px" }}>
					<div className={ styles.avatarDiv }>
						<div className={ styles.avatarDivLeft }>
							<Avatar
								src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80"
								size="50px" />
							<div style={{ marginLeft: "10px" }}>Jasmine Wiggins</div>
						</div>
						<div>
							<Rating
								rating={ 4 } />
						</div>
					</div>
				</Card>


				<div>

					<Card style={{ marginBottom: "20px" }}>
						<Statistic
							title="Tesla Inc"
							value={ 256.59 }
							prevValue={ 235.20 }
							currency="usd"
						/>
					</Card>

				</div>

				<Card style={{ marginBottom: "20px" }}>
				  <div className={ styles.progressWrapper }>
						<RadialProgress
						  size="150px"
							prcnt="75" />

						<div className={ styles.progressionDiv }>
							<h6>Progression</h6>
							<p>3 of 4 folders uploaded</p>
							<Button type="primary" loading={ true }>Submit</Button>
						</div>
					</div>
				</Card>



				<Card style={{ marginBottom: "20px" }}>
					<ArticleCard
						img="https://images.unsplash.com/photo-1530447920184-b88c8872?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80"
						title="The Shocking Revelation of AI."
						category="Artificial intelligence"
						author="Nyle Webb"
						time="25 min ago"
					/>
				</Card>


				<div>

					<Card style={{ marginBottom: "20px" }}>
						<div className={ styles.userCard }>
							<Badge count={ 3 }>
								<Avatar
									src="https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1002&q=80"
									size="70px" />
							</Badge>
							<h4 style={{ fontSize: "25px", margin: 0 }}>Nyle Guzman</h4>
							<div style={{ marginBottom: "50px", opacity: .5 }}>Senior Software Engineer</div>
							<div>
								<Button round type="primary">Contact</Button>
								<Button style={{ marginLeft: "10px" }} outlined round type="primary">Follow</Button>
							</div>
						</div>
					</Card>

				</div>
			</div>
		)
	}
}

export default FeaturesList;
