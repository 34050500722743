import React, { Component } from 'react';
import { Button, Row, Col, Input, message } from "flwww";
import styles from "../Homepage.module.scss";
import HomepageImage2 from "../../../images/SVG/HomepageImage2.svg";
import firebase from "firebase";
import { validate } from 'email-validator';
import RollingFeatures from "./RollingFeatures";


const firebaseConfig = {
	apiKey: "AIzaSyBNYkBVR1S5nXAmdplmQ-GX0sZETsVm89c",
	authDomain: "flaless-806ca.firebaseapp.com",
	databaseURL: "https://flaless-806ca.firebaseio.com",
	projectId: "flaless-806ca",
	storageBucket: "flaless-806ca.appspot.com",
	messagingSenderId: "48129681564",
	appId: "1:48129681564:web:9fbdda63d61c775b"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


class Newsletter extends Component {
	state = {
		email: "",
		inputError: false,
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			inputError: false,
		});
	}

	addToEmailList = () => {
		const isEmailValid = validate(this.state.email);
		console.log(this.state.email, "?", isEmailValid);
		if(isEmailValid){
			const db = firebase.firestore();
			const { email } = this.state;

			const self = this;

			db.collection("users").doc(email).set({
				email,
			})
			.then(function(docRef) {
				message("Thanks for subscribing to our newsletter 🙂", "success");
				self.setState({ email: "" });
			})
			.catch(function(error) {
				message("Something went wrong... Please try again", "error");
			});
		}
		else {
			message("This is not a valid email...", "error");
			this.setState({ inputError: true });
		}
	}


	render(){
		return (
			<div
				className={ `${ styles.tutorialsWrapper } ${ styles.newsletterWrapper }` }
				style={{ paddingBottom: "14rem" }}>
			  <Row className={ styles.newsletterDiv }>

					{/*<Col grid="md-5">
						<div className={ styles.homeImgDiv }>
							<img
								src={ HomepageImage2 }
								alt="tutorial image"
								className={ styles.homeImg }
								style={{ width: "100%" }}/>
						</div>
					</Col>*/}

					<RollingFeatures />

					<Col grid="md-5 offset-md-2">
						<div className={ styles.homePageText }>
							<h3
								style={{ margin: 0 }}
								className="componentTitle">Newsletter</h3>
							<p
								className={ styles.homePagePar }
								style={{ marginTop: "5px" }}>Receive the latest features and releases right into your inbox!</p>

							<div className={ styles.newsletterInputDiv }>
								<Input
									name="email"
									onChange={ this.handleChange }
									value={ this.state.email }
									placeholder="Enter your email"
									error={ this.state.inputError } />
							</div>

							<Button
								onClick={ this.addToEmailList }
								type="primary">Subscribe!</Button>
						</div>
					</Col>

				</Row>
			</div>
		)
	}
}

export default Newsletter;
