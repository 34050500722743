import React from 'react';
import styles from "./Checkbox.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import { codeString, codeString2 } from "./CodeString";
import ComponentsToRender from "./ComponentsToRender";
import ComponentsToRender2 from "./ComponentsToRender/ComponentsToRender2";
import apiData from "./apiData";

import CarbonAds from "../../../CarbonAds";

const Checkbox = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">Checkbox</h1>

				<DocBlock
					sectionName="default"
					subTitle="A checkbox toggle control."
					codeString={ codeString }>
					<ComponentsToRender />
				</DocBlock>

				<DocBlock
					sectionName="disabled"
					subTitle="Disabled checkbox."
					codeString={ codeString2 }>
					<ComponentsToRender2 />
				</DocBlock>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default Checkbox;
