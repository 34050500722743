import React from 'react';
import { Button, Row, Col } from "flwww";
import styles from "../Homepage.module.scss";
import HomepageImage1 from "../../../images/SVG/HomepageImage1.svg";

const Tutorial = (props) => {

	return (

		<div
			style={{ margin: "4rem 0 8rem" }}>

				<Row className={ styles.mainTutorialWrapper }>

				<Col grid="md-6">
					<div className={ styles.homePageText }>
						<h3
							style={{ margin: 0 }}
							className="componentTitle">Tutorial</h3>
						<p
							className={ styles.homePagePar }
							style={{ marginTop: "5px" }}>You can find official tutorials about topics related to Flawwwless ui on our Medium page.</p>
						<a
							href="https://medium.com/flawwwless-ui"
							target="_blank"
							rel="noopenner noreferrer">
							<Button type="primary">Read now!</Button>
						</a>
					</div>
				</Col>

				<Col grid="md-5 offset-md-1">
					<div className={ styles.homeImgDiv }>
						<img
							src={ HomepageImage1 }
							alt="tutorial image"
							className={ `${ styles.homeImg } ${ styles.reactLogoImg }`}/>
					</div>
				</Col>

			</Row>
	  </div>

	)
}

export default Tutorial;
