import React from "react";
import { Menu, Card } from "flwww";

const ComponentsToRender = () => (
  <div>
    <Card padding={ "0" }>
			<Menu
	      style={{ width: 250, padding: "20px 0" }}
	      selectedItem={ "home" }>

	      <Menu.Item
	        uniqueKey={ "home" }>
	        <span>Home</span>
	      </Menu.Item>

	      <Menu.Item
	        uniqueKey={ "products" }>
	        <span>Products</span>
	      </Menu.Item>

	      <Menu.Item
	        uniqueKey={ "pricing" }>
	        <span>Pricing</span>
	      </Menu.Item>

	      <Menu.Item
	        uniqueKey={ "support" }>
	        <span>Support</span>
	      </Menu.Item>

	      <Menu.Item
	        uniqueKey={ "explore" }>
	        <span>Explore</span>
	      </Menu.Item>

	    </Menu>
		</Card>
  </div>
);

export default ComponentsToRender;
