import React from 'react';
import styles from "./Statistic.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import CodeString from "./CodeString";
import ComponentsToRender from "./ComponentsToRender";
import apiData from "./apiData";

import CarbonAds from "../../../CarbonAds";

const Statistic = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">Statistic</h1>

				<DocBlock
					sectionName="default"
					subTitle="Dislpay beautiful statistic effortlessly."
					codeString={ CodeString }>
					<ComponentsToRender />
				</DocBlock>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default Statistic;
