import React from 'react';
import styles from "./Avatar.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import { codeString1, codeString2, codeString3 } from "./CodeString";
import { ComponentsToRender1, ComponentsToRender2, ComponentsToRender3 } from "./ComponentsToRender";
import apiData from "./apiData";
import CarbonAds from "../../../CarbonAds";

const Avatar = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">Avatar</h1>

				<DocBlock
					sectionName="default"
					subTitle="Adding Avatar easily."
					codeString={ codeString1 }>
					<ComponentsToRender1 />
				</DocBlock>

				<DocBlock
					sectionName="initials"
					subTitle="Automatically resize fonts when using initials."
					codeString={ codeString2 }>
					<ComponentsToRender2 />
				</DocBlock>

				<DocBlock
					sectionName="custom"
					subTitle="Fully customizable!"
					codeString={ codeString3 }>
					<ComponentsToRender3 />
				</DocBlock>

				<p>📷 All the images have been taken from <a href="https://unsplash.com/" target="_blank" rel="noopenner noreferrer">unsplash.com</a>.</p>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default Avatar;
