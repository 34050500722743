import React, { Component } from 'react';
import DocBlock from "../../DocBlock";
import ApiTable from "../../ApiTable";
import { Icon, Row, Col, message, Input } from "flwww";
import styles from "../FlwwwIcon.module.scss";
import copy from 'copy-to-clipboard';

const emojiArr = ["🙌", "👍", "👌", "👊"];

const iconsList = Icon.iconList;

class IconSearch extends Component {
	state = {
		searchText: "",
		iconsList: iconsList,
	}

	handleChange = async (e) => {
		const searchText = e.target.value;
		await this.setState({
			[e.target.name]: searchText,
		});
		if(searchText === ""){
			this.setState({ iconsList: iconsList });
		}
		else {
			// filter the icons
			const searchLength = searchText.length;
			const searchresult = iconsList.filter(icon => icon.slice(0, searchLength) === searchText);
			this.setState({ iconsList: searchresult });
		}
		console.log(searchText);
	}

	render(){

		const copyMessage = (iconName) => {
			const randomIndex = Math.floor(Math.random() * emojiArr.length);
			const emoji = emojiArr[randomIndex];
			const copyMsg = `<Icon type="${ iconName }" />`
			copy(copyMsg);
			message(`${ copyMsg } copied  ${ emoji }`, "success");
		}

		return(
			<div>
				<div>
					<Input
						placeholder="Search icons"
						autoFocus
						icon="search"
						name="searchText"
						value={ this.state.searchText }
						onChange={ this.handleChange } />
				</div>

				<DocBlock
					sectionName="icon-search"
					subTitle="Click on the icon you want to use:">
					<Row>
						  {
								this.state.iconsList.map((iconName, i) => (
									<Col
									  grid="6 md-4 lg-3 xl-2">
										<div className={ styles.iconBtnDiv }>
											<div
											  onClick={ () => copyMessage(iconName) }
											  className={ styles.iconBtn }>
											  <Icon type={ iconName } />

												<div className={ styles.iconName }>{ iconName }</div>
											</div>
										</div>
									</Col>
								))
							}
					</Row>

					{
						this.state.searchText !== "" && this.state.iconsList.length === 0 && (
							<p style={{ color: "#006EFF" }}>Sorry, there is no result for your search...</p>
						)
					}
				</DocBlock>
			</div>
		)
	}
}

export default IconSearch;
