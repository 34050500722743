import React, { Component } from "react";
import { Button, message } from "flwww";

const buttonStyle = {
	marginRight: "10px",
	marginBottom: "10px",
}

class LoadingBtnsToRender extends Component {
  state = {
    isLoading: false,
  }

  fakeLoad = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
      // Tell the user the loading is finished
      message("Loading is finished!", "success");
    }, 2000);
  }

  render(){
    return (
      <div>
        <Button
				  style={ buttonStyle }
          loading={ this.state.isLoading }
          onClick={ this.fakeLoad }
          type="primary">Click me!</Button>

        <Button
				  style={ buttonStyle }
          loading >Default</Button>

        <Button
				  style={ buttonStyle }
          loading
          outlined
          type="primary">Primary</Button>

        <Button
				  style={ buttonStyle }
          loading
          type="success"
          round >Success</Button>

        <Button
				  style={ buttonStyle }
          loading
          outlined
          type="danger" round>Danger</Button>
      </div>
    );
  }
}

export default LoadingBtnsToRender;
