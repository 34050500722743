import React, { Component } from "react";
import { Badge, Avatar } from "flwww";

import BadgeCount from "./BadgeCount";

class ComponentsToRender1 extends Component {

	render(){

		return(
			<div>
				<Badge dot>
					<Avatar
						src="https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1002&q=80"
						size="50px" />
				</Badge>

				<Badge
					count={ 5 } >
					<Avatar
						style={{ marginLeft: "20px" }}
						src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
						size="50px" />
				</Badge>

				<Badge text="new">
					<Avatar
						style={{ marginLeft: "20px" }}
						src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80"
						size="50px" />
				</Badge>

			</div>
		)
	}
}


class ComponentsToRender2 extends Component {

	render(){

		return(
			<div>
				<Badge
					count={ 2 }
					style={{ color: "#006eff", border: "2px solid #006eff", backgroundColor: "#fff" }}>
					<Avatar
						style={{ borderRadius: "4px" }}
						src="https://images.unsplash.com/photo-1489424731084-a5d8b219a5bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80"
						size="50px" />
				</Badge>
			</div>
		)
	}
}



export { ComponentsToRender1, ComponentsToRender2, BadgeCount as ComponentsToRender3 };
