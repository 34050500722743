import React, { Component } from "react";
import { Table } from "flwww";
import { Link } from "react-router-dom";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "type",
		Description: <span>The type of input, see <a href="https://developer.mozilla.org/fr/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types" target="_blank" rel="noopenner noreferrer" >MDN</a> for more information.</span>,
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "icon",
		Description: <span>Add an icon as prefix, see <Link to="/documentation/icon">Icons</Link> for more information.</span>,
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "error",
		Description: `Add styling to show the user that the input entry is invalid.`,
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "noSpin",
		Description: `Remove the spin buttons on the number input.`,
		Type: "Boolean",
		Default: "undefined",
	},
	{
		Property: "style",
		Description: `Override the input style (e.g. { borderRight: "none" }).`,
		Type: "Object",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
