import React from 'react';
import styles from "./ArticleCard.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import CodeString from "./CodeString";
import ComponentsToRender from "./ComponentsToRender";
import apiData from "./apiData";

import CarbonAds from "../../../CarbonAds";

const ArticleCard = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">ArticleCard</h1>

				<DocBlock
					sectionName="default"
					subTitle="Build awesome article card for your blog articles quickly."
					codeString={ CodeString }>
					<ComponentsToRender />
				</DocBlock>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default ArticleCard;
