import React, { Component } from "react";
import { Card, Col, Row, Button } from "flwww";
import styles from "../Layout.module.scss";

const SingleGrid = (props) => {
	const singleGrid = ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1"];
	return (
		<Row>
			{ singleGrid.map((grid, i) => (
				<Col
					grid={ grid }>
					<div className={ `${ styles.displayCol } ${ styles.displayBgCol }` } ></div>
				</Col>
			))}
    </Row>
	)
}

const FormatLayout = (props) => {
	return (
		<div style={{ position: "relative" }}>
			<SingleGrid />

			<div className={ styles.rowOverlay }>
				<Row>
					{
						props.gridArr.map((grid, i) => (
							<Col grid={ grid }>
								<div className={ `${ styles.displayCol } ${ styles.blueCol }` }>grid="{ grid }"</div>
							</Col>
						))
					}
				</Row>
			</div>
		</div>
	)
}


const ComponentsToRender1 = (props) => {

  return (
    <div>
			<FormatLayout gridArr={ [ "12" ]} />
			<FormatLayout gridArr={ [ "6", "6" ]} />
			<FormatLayout gridArr={ [ "4", "4", "4" ]} />
			<FormatLayout gridArr={ [ "3", "3", "3", "3" ]} />
			<FormatLayout gridArr={ [ "7", "5" ]} />
			<FormatLayout gridArr={ [ "2", "9 offset-1" ]} />
			<SingleGrid />
		</div>
  )
};


const ComponentsToRender2 = (props) => {

	const colStyle = {
	  display: "flex",
	  justifyContent: "center",
	  backgroundColor: "#006effaa",
	  color: "#fff",
	  padding: "2rem 0",
	  borderRadius: "2px",
	}


  return (
		<div style={{ margin: "40px 0 20px" }}>
			<Row>
	      <Col grid="6">
	        <div style={ colStyle }>grid="6" (50% of the width)</div>
	      </Col>

	      <Col grid="6">
	        <div style={ colStyle }>grid="6" (50% of the width)</div>
	      </Col>
	    </Row>
		</div>
  )
};

const ComponentsToRender3 = (props) => {

	const colStyle = {
	  display: "flex",
	  justifyContent: "center",
	  backgroundColor: "#006effaa",
	  color: "#fff",
	  padding: "2rem 0",
	  borderRadius: "2px",
	}


  return (
		<div style={{ margin: "40px 0 20px" }}>
			<Row>
	      <Col grid="offset-3 6">
	        <div style={ colStyle }>grid="offset-3 6"</div>
	      </Col>

	    </Row>
		</div>
  )
};


const ComponentsToRender4 = (props) => {

	const colStyle = {
	  display: "flex",
	  justifyContent: "center",
	  backgroundColor: "#006effaa",
	  color: "#fff",
	  padding: "2rem 0",
	  borderRadius: "2px",
	}


  return (
		<div style={{ margin: "40px 0 20px" }}>
			<Row>
				<Col grid="sm-8 md-6 lg-5 xl-4">
	        <div style={ colStyle }>grid="sm-8 md-6 lg-5 xl-4"</div>
	      </Col>

	    </Row>
		</div>
  )
};


export { ComponentsToRender1, ComponentsToRender2, ComponentsToRender3, ComponentsToRender4 };
