import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "loading",
		Description: "Whether your app is loading or not.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "style",
		Description: "Override the Loader styling.",
		Type: "Object",
		Default: "{}",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
