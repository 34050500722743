import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "elementList",
		Description: "Takes a list of HTML elements or components (ex: links, button, etc.) you want to display inside the dropdown menu.",
		Type: "Array of Components",
		Default: "undefined",
	},
	{
		Property: "position",
		Description: <span>Change the position of the dropdown. You can choose between <code>"bottom-left"</code>, <code>"bottom-right"</code>, <code>"top-left"</code> & <code>"top-right"</code></span>,
		Type: "String",
		Default: `"bottom-left"`,
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
