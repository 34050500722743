const codeString1 = `import React, { Component } from "react";
import { Badge, Avatar } from "flwww";

class MyComponent extends Component {

	render(){
		return(
			<div>
				<Badge dot>
					<Avatar
						src="https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1002&q=80"
						size="50px" />
				</Badge>

				<Badge
					count={ 5 } >
					<Avatar
						style={{ marginLeft: "20px" }}
						src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
						size="50px" />
				</Badge>

				<Badge text="new">
					<Avatar
						style={{ marginLeft: "20px" }}
						src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80"
						size="50px" />
				</Badge>

			</div>
		)
	}
}

export default MyComponent;`;


const codeString2 = `import React from "react";
import { Badge, Avatar } from "flwww";

const MyComponent = () => {

	return (
		<div>
			<Badge
				count={ 2 }
				style={{ color: "#006eff", border: "2px solid #006eff", backgroundColor: "#fff" }}>
				<Avatar
					style={{ borderRadius: "4px" }}
					src="https://images.unsplash.com/photo-1489424731084-a5d8b219a5bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80"
					size="50px" />
			</Badge>
		</div>
	)
}

export default MyComponent;`;


const codeString3 = `import React, { Component } from 'react';
import { Badge, Avatar, Button } from "flwww";

class MyComponent extends Component {
	state = {
		notifCounter: 1,
	}

	addNotif = () => {
		this.setState(prevState => ({
			notifCounter: prevState.notifCounter + 1,
		}));
	}

	removeNotif = () => {
		// Check if the notifCounter is > 0
		if(this.state.notifCounter > 0){
			this.setState(prevState => ({
				notifCounter: prevState.notifCounter - 1,
			}));
		}
	}


	render(){
		return (
			<div style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					margin: "60px 0",
				}}>
				<div>
					<Badge count={ this.state.notifCounter }>
						<Avatar
						  src="https://images.unsplash.com/photo-1446511437394-36cdff3ae1b3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
						  size="50px" />
					</Badge>
				</div>

				<div style={{ marginTop: "20px" }}>
					<Button
					  round
						onClick={ this.addNotif }
					  type="success">Plus</Button>
					<Button
					  round
						disabled={ this.state.notifCounter < 1 }
						onClick={ this.removeNotif }
						style={{ marginLeft: "10px" }}
					  type="danger">Minus</Button>
				</div>
			</div>
		)
	}
}

export default MyComponent;`;



export { codeString1, codeString2, codeString3 };
