import React from 'react';
import styles from "./showCode.module.scss";
import Highlight from "react-highlight.js";


const ShowCode = (props) => {
	const codeString = props.codeString.replace(/\t/g, "  ");
	return (
		<div className={ styles.showCodeDiv }>

			<Highlight language={ "jsx" }>
		    { codeString }
		  </Highlight>
		</div>
	)
}

export default ShowCode;
