const codeString1 = `import React from "react";
import { Input } from "flwww";

const MyComponent = () => (
  <div style={{ width: "500px" }}>
    <Input placeholder="Simple input" />
  </div>
);

export default MyComponent;`;


const codeString2 = `import React from "react";
import { Input } from "flwww";

const MyComponent = () => (
  <div style={{ width: "500px" }}>
    <Input
      icon="search"
      placeholder="Search something"
      type="search" />
  </div>
);

export default MyComponent`;

const codeString3 = `import React from "react";
import { Input } from "flwww";

const MyComponent = () => (
  <div style={{ width: "500px" }}>
		<Input
      error={ true }
      placeholder="Simple form error handling" />
  </div>
);

export default MyComponent`;


const codeString4 = `import React from "react";
import { Input } from "flwww";

const MyComponent = () => (
  <div style={{ width: "500px" }}>
		<Input
      style={{ marginBottom: ".7rem" }}
      placeholder="This is a text input" />

    <Input
      style={{ marginBottom: ".7rem" }}
      type="number"
      placeholder="This is a number input" />

    <Input
      style={{ marginBottom: ".7rem" }}
      placeholder="This is a search input"
      type="search" />

    <Input
      style={{ marginBottom: ".7rem" }}
      placeholder="This is a password input"
      type="password" />

    <TextArea
      rows={ 4 }
      placeholder="This is a textArea" />
  </div>
);

export default MyComponent`;


const codeString5 = `import React from "react";
import { Input } from "flwww";

const MyComponent = () => (
  <div style={{ width: "500px" }}>
		<Input
      style={{ fontSize: "2rem", marginBottom: ".7rem" }}
      icon="user"
      placeholder="username" />

    <Input
      style={{ borderRadius: "1rem", color: "blue" }}
      placeholder="This is a rounded input" />
  </div>
);

export default MyComponent`;


export { codeString1, codeString2, codeString3, codeString4, codeString5 };
