import React, { Component } from 'react';
import "./CarbonAds.scss";

class CarbonAds extends Component {
	state = {

	}

  componentDidMount() {
      const carbon_wrapper = document.querySelector(`.${this.state.carbonWrapper ? this.state.carbonWrapper : 'carbon-adds-wrapper'}`);
      const script = document.createElement("script");
      script.src = "//cdn.carbonads.com/carbon.js?serve=CE7D6KJI&placement=flawwwlesscom";
      script.async = true;
      script.id = "_carbonads_js"
      carbon_wrapper.appendChild(script);
  }

  render() {
      return (
          <div
					  className={`carbon-adds-wrapper`}>
					</div>
      );
  }
}

export default CarbonAds;
