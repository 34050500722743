const codeString = `import React, { Component } from "react";
import { Checkbox } from "flwww";

class MyComponent extends Component {
	state = {
		checkbox: true,
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	render(){

		return (
			<div>
				<Checkbox
					name="checkbox"
					onChange={ this.handleChange }
					checked={ this.state.checkbox }>Checkbox</Checkbox>
			</div>
		)
	}
}

export default MyComponent;`;


const codeString2 = `import React, { Component } from "react";
import { Checkbox } from "flwww";

class MyComponent extends Component {
	state = {
		checkbox: false,
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	render(){

		return (
			<div>
				<Checkbox
					name="checkbox"
					onChange={ this.handleChange }
					disabled={ true }
					checked={ this.state.checkbox }>Disabled Checkbox</Checkbox>
			</div>
		)
	}
}

export default MyComponent;`;


export { codeString, codeString2 };
