import React, { Component } from "react";
import { Table } from "flwww";
import styles from "../ThemeProvider.module.scss";


class ComponentsToRender extends Component {

  render(){
    return (
			<div>
				<p>At first, you need to know that there is 8 possibles colors to customize:</p>

				<ol className={ styles.customThemeList }>
					<li><p>The <code>primaryColor</code> controls the primary button main color. The primary color controls also the menu main color, the Table row hovered background color and the Input main color on focus.</p></li><li><p>The <code>primaryTextColor</code> controls the primary button text color if the button is not outlined.</p></li>

				  <li><p>The <code>defaultColor</code> controls the default button main color.</p></li>

				  <li><p>The <code>defaultTextColor</code> controls the default button text color if the button is not outlined.</p></li>

				  <li><p>The <code>successColor</code> controls the success button main color.</p></li>

				  <li><p>The <code>successTextColor</code> controls the success button text color if the button is not outlined.</p></li>

				  <li><p>The <code>dangerColor</code> controls the danger button main color.</p></li>

				  <li><p>The <code>dangerTextColor</code> controls the danger button text color if the button is not outlined.</p></li>

				</ol>

				<p>Now you need to wrap your application inside a <code>ThemeProvider</code> component. We recommand to do this inside your index.js file located in your root folder. Once wrapped, you now need to give the <code>ThemeProvider</code> component a theme props with the colors object using 6 hexadecimal colors that you want to use for your app inside an object like the following:</p>
			</div>
		)
  }
}

export default ComponentsToRender;
