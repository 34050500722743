const codeString1 = `import React from "react";
import { Container } from "flwww";

const MyComponent = () => (
  <Container>
    <div>This is a Container!</div>
  </Container>
);

export default MyComponent;`;


const codeString2 = `import React from "react";
import { Container } from "flwww";

const MyComponent = () => (
  <Container full>
    <div>This is a full width Container!</div>
  </Container>
);

export default MyComponent;`;

const codeString3 = `import React from "react";
import { Container, Row, Col } from "flwww";

// Just adding some style to the Col component
const colStyle = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#006effaa",
  color: "#fff",
  padding: "2rem 0",
  borderRadius: "2px",
}

const MyComponent = () => (
  <Container full>
		<Row>
			<Col grid="6">
				<div style={ colStyle }>grid="6" (50% of the width)</div>
			</Col>

			<Col grid="6">
				<div style={ colStyle }>grid="6" (50% of the width)</div>
			</Col>
		</Row>
  </Container>
);

export default MyComponent;`;


const codeString4 = `import React from "react";
import { Container, Row, Col } from "flwww";

// Just adding some style to the Col component
const colStyle = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#006effaa",
  color: "#fff",
  padding: "2rem 0",
  borderRadius: "2px",
}

const MyComponent = () => (
  <Container full>
		<Row>

			<Col grid="offset-3 6">
				<div style={ colStyle }>grid="offset-3 6"</div>
			</Col>

		</Row>
  </Container>
);

export default MyComponent;`;

const codeString5 = `import React from "react";
import { Container, Row, Col } from "flwww";

// Just adding some style to the Col component
const colStyle = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#006effaa",
  color: "#fff",
  padding: "2rem 0",
  borderRadius: "2px",
}

const MyComponent = () => (
  <Container full>
		<Row>

			<Col grid="sm-8 md-6 lg-5 xl-4">
        <div style={ colStyle }>sm-8 md-6 lg-5 xl-4</div>
      </Col>

		</Row>
  </Container>
);

export default MyComponent;`;




export { codeString1, codeString2, codeString3, codeString4, codeString5 };
