import React, { Component } from "react";
import { Table } from "flwww";

class ComponentsToRender extends Component {

  render(){
    // Here is the data for the table columns
    const columns = [ "Product", "Color", "Quantity", "Price" ];

    // Here is the data for the table rows. Pay attention to have the same key name as the one in your columns array!
    const products = [{
      Product: "T-Shirt",
      Color: "Red",
      Quantity: 23,
      Price: 32.99,
    },
    {
      Product: "Shoes",
      Color: "Black",
      Quantity: 2,
      Price: 119.99,
    },
    {
      Product: "Winter Jacket",
      Color: "Blue",
      Quantity: 10,
      Price: 99.99,
    },
    {
      Product: "Socks",
      Color: "White",
      Quantity: 21,
      Price: 7.99,
    },
    {
      Product: "Cap",
      Color: "Green",
      Quantity: 29,
      Price: 29.99,
    }];

    return (
      <div>
        <Table
          bordered
          columns={ columns }
          rows={ products } />
      </div>
    )
  }
}

export default ComponentsToRender;
