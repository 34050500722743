const codeString1 = `import React from "react";
import { Avatar } from "flwww";

const MyComponent = () => {

	return (
		<div>
			<Avatar
				src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80" />
		</div>
	)
}

export default MyComponent;`;


const codeString2 = `import React from "react";
import { Avatar } from "flwww";

const MyComponent = () => {

	return (
		<div>
			<div style={{ display: "flex" }}>
				<Avatar
					style={{ marginRight: "10px" }}
					initials="S" />

				<Avatar
					style={{ marginRight: "10px" }}
					initials="JR" />

				<Avatar
					style={{ marginRight: "10px" }}
					initials="Sam" />

				<Avatar
					style={{ marginRight: "10px" }}
					initials="Julia" />

				<Avatar
					style={{ marginRight: "10px" }}
					initials="Sammy" />
			</div>
		</div>
	)
}

export default MyComponent;`;


const codeString3 = `import React from "react";
import { Avatar } from "flwww";

const MyComponent = () => {

	return (
		<div>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Avatar
					size="60px"
					style={{ marginRight: "10px" }}
					src="https://images.unsplash.com/photo-1489424731084-a5d8b219a5bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80" />

				<Avatar
					size="60px"
					style={{ marginRight: "10px", backgroundColor: "#006eff", color: "#fff", borderRadius: "4px" }}
					initials="F" />

				<Avatar
					src="https://images.unsplash.com/photo-1562206932-a4bc8615c1f1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
					style={{ marginRight: "10px", borderRadius: "0" }}
					initials="Sam" />

				<Avatar
					style={{ marginRight: "10px", border: "2px solid #006eff", color: "#006eff", backgroundColor: "#ffffff" }}
					initials="Julia" />
			</div>
		</div>
	)
}

export default MyComponent;`;



export { codeString1, codeString2, codeString3 };
