import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "showDrawer",
		Description: "Whether the drawer is visible or not.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "toggleDrawer",
		Description: "Specify a function that control the drawer visibiliity state.",
		Type: "Function",
		Default: "undefined",
	},
	{
		Property: "position",
		Description: `Whether you want the drawer at left ("left") or at right ("right")`,
		Type: "String",
		Default: "right",
	},
	{
		Property: "isClosable",
		Description: "Wether want to remove the close icon button at the top right of the drawer.",
		Type: "Boolean",
		Default: "true",
	},
	{
		Property: "style",
		Description: `Override the drawer style (e.g. { backgroundColor: "lightblue" }).`,
		Type: "Object",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
