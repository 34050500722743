import React, { Component } from "react";
import { Rating, message } from "flwww";

class ComponentsToRender1 extends Component {
	state={
			rating: null,
		}

		handleRatingChange = (rating) => {
			// Alert the rating ;)
			message(`Rating: ${ rating }/5`, "success");
			this.setState({ rating });
		}

		render(){

			return(
				<Rating
					rating={ this.state.rating }
					handleRatingChange={ this.handleRatingChange } />
			)
		}
}


class ComponentsToRender2 extends Component {

	render(){

		return(
			<Rating
				color={ "#ffdd00" }
				size={ "50px" }
				rating={ 4 }
				isStatic
			/>
		)
	}
}

export { ComponentsToRender1, ComponentsToRender2 };
