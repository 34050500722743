import React, { Component } from "react";
import { Card, Col, Row, Button } from "flwww";


const ComponentsToRender1 = (props) => {

  return (
    <Row>
      <Col grid="lg-6">

				<Card title="Title of the card">
	        <p>Put anything you want inside!</p>
	      </Card>

			</Col>
    </Row>
  )
};


const ComponentsToRender2 = (props) => {

  return (
		<Row>
      <Col grid="lg-6">

				<Card
					style={{ boxShadow: "0 10px 20px #0003", border: "none" }}>
						<h3 style={{ marginTop: ".5rem" }}>A card with shadow</h3>
						<p>You can put whatever you want inside those cards and customize it using style props.</p>

					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button round type="primary">Continue</Button>
					</div>
				</Card>

			</Col>
    </Row>
  )
}


export { ComponentsToRender1, ComponentsToRender2 };
