import React from 'react';
import { Link } from "react-router-dom";
import styles from "./Intro.module.scss";
import { Button, message } from "flwww";
import copy from 'copy-to-clipboard';
import CarbonAds from "../../../CarbonAds";

const emojiArr = ["🙌", "👍", "👌", "👊"];

const Intro = (props) => {

	const copiedMessage = (msg) => {
		const randomIndex = Math.floor(Math.random() * emojiArr.length);
		const emoji = emojiArr[randomIndex];
		copy(msg);
		message(`${ msg } copied  ${ emoji }`, "success");
	}

	return (
		<div className="fadeIn">
			<CarbonAds />
			<h1>Introduction to Flawwwless ui</h1>

			<p>The idea behind Flawwwless ui was to build an open source React.js components library to help developers to create high quality application. With that in mind, we also want the components to be easily integrated and customized to match any existing application without messing with existing styles.</p>

			<p>Let’s dive in the Flawwwless ui ecosystem that will help you and your team to build awesome apps!</p>


			<div style={{ "padding": "0rem 0px 1rem" }}>
				<h3 className="componentTitle">Installation</h3>
				<h4>Using yarn or npm</h4>

			  <button className={ styles.copyInstallBtn }>
					<div
						onClick={ () => copiedMessage("yarn add flwww") }
						className={ styles.terminalCode }>
						<span className={ styles.codeToCopy }>$ yarn add flwww</span>
						<span className={ styles.clickToCopyText }>Click to copy!</span>
					</div>
				</button>

				<div style={{ paddingLeft: "1rem" }}>or</div>

					<button className={ styles.copyInstallBtn }>
						<div
							onClick={ () => copiedMessage("npm install flwww --save") }
							className={ styles.terminalCode }>
							<span className={ styles.codeToCopy }>$ npm install flwww --save</span>
							<span className={ styles.clickToCopyText }>Click to copy!</span>
						</div>
					</button>
			</div>

			<div style={{ "padding": "0rem 0px 2rem" }}>

				<h3 className="componentTitle">Font used (optional)</h3>

				<p>If you want your app to look similar than on <code>ui.flawwwless.com</code>, we recommend you to use the <a href="https://fonts.google.com/specimen/Source+Sans+Pro" target="_blank" rel="noopenner noreferrer">Source Sans Pro</a> available by Google for free. We are using the <code>400</code> and <code>600</code> font-weight.</p>

			  <p>To embed your selected fonts into a webpage, copy the code code below into the <code>{ `<head>` }</code> of your HTML document:</p>

				<button className={ styles.copyInstallBtn }>
					<div
						onClick={ () => copiedMessage(`<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap" rel="stylesheet">`) }
						className={ styles.terminalCode }>
						<span className={ styles.codeToCopy }>{ `<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap" rel="stylesheet">` }</span>
						<span className={ styles.clickToCopyText }>Click to copy!</span>
					</div>
				</button>

				<p>Once done, use the following CSS rules to specify these families. We recommend you to adding it to the body element. For examples of how fonts can be added to webpages, see the <a href="https://developers.google.com/fonts/docs/getting_started" target="_blank" rel="noopenner noreferrer">getting started guide</a> made by Google.</p>

				<button className={ styles.copyInstallBtn }>
					<div
						onClick={ () => copiedMessage("font-family: 'Source Sans Pro', sans-serif;") }
						className={ styles.terminalCode }>
						<span className={ styles.codeToCopy }>font-family: 'Source Sans Pro', sans-serif;</span>
						<span className={ styles.clickToCopyText }>Click to copy!</span>
					</div>
				</button>

			</div>


			<Link to="/documentation/button">
				<Button type="primary">Get started!</Button>
			</Link>
		</div>
	)
}

export default Intro;
