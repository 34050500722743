import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "checked",
		Description: "Whether the Switch is checked/selected or not.",
		Type: "Boolean",
		Default: "undefined",
	},
	{
		Property: "name",
		Description: "Name of the checkbox input.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "onChange",
		Description: "The callback function that is triggered when the state changes.",
		Type: "Function",
		Default: "undefined",
	},
	{
		Property: "disabled",
		Description: "Whether the Switch component is disabled or not.",
		Type: "Boolean",
		Default: "false",
	},
];

const apiData = {
	columns,
	rows,
}


export default apiData;
