import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "type",
		Description: "This is the name of the Flawwwless Icon you want.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "size",
		Description: `This control the size of the icon. If not specified, it will take the size of it's nearest parent.`,
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "color",
		Description: `Here you can set the color of the icon.`,
		Type: "String",
		Default: "#000000",
	},
	{
		Property: "isSpinning",
		Description: `Control whether or not the icon is spinning.`,
		Type: "Boolean",
		Default: "false",
	},
	// {
	// 	Property: "style",
	// 	Description: `Override the Icon style (e.g. { border: "1px solid red" }).`,
	// 	Type: "Object",
	// 	Default: "undefined",
	// },
];

const apiData = {
	columns,
	rows,
}

export default apiData;
