import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "rating",
		Description: <span>A number between <code>0</code> and <code>5</code> or <code>undefined</code>. This is normally stored inside the state of a component.</span>,
		Type: "Number",
		Default: "undefined",
	},
	{
		Property: "handleRatingChange",
		Description: "Specify a function that control the rating (Function that undate the rating stored inside the state).",
		Type: "Function",
		Default: "undefined",
	},
	{
		Property: "color",
		Description: <span>Specify the color of the selected stars. The default color is the color stored inside the <code>primaryColor</code> (<code>#006eff</code>)</span>,
		Type: "String",
		Default: "#006eff",
	},
	{
		Property: "isStatic",
		Description: "Remove the animation on hover (i.e.: to display the rating).",
		Type: "Boolean",
		Default: "undefined",
	},
	{
		Property: "size",
		Description: "Specify the size of every stars.",
		Type: "String",
		Default: "33px",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
