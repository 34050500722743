import React from 'react';
import styles from "./ErrorPage.module.scss";
import { Button } from "flwww";
import { Link } from "react-router-dom";

const ErrorPage = (props) => {
	return(
		<div className={ styles.errorPageWrapper }>
		  <div className={ styles.errorMsg }>
				<div className={ styles.error404 }>404</div>
			  <div className={ styles.errorTitle }>Woaaaa! That's an error.</div>
			</div>

			<p className={ styles.errorPar }>The page you're looking for can't be found. Why don't you dive to the doc?</p>

			<Link to="/documentation/intro">
			  <Button type="primary">Dive to documentation</Button>
			</Link>
		</div>
	)
}

export default ErrorPage;
