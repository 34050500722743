import React from 'react';
import { Container, Row, Col, Button } from "flwww";
import styles from "./Homepage.module.scss";
import { Link } from "react-router-dom";
import HomepageLogo from "../../images/SVG/HomepageLogo.svg";
import GithubButton from "./GithubButton";
import Tutorial from "./Tutorial";
import Newsletter from "./Newsletter";
import Details from "./Details";


const Homepage = (props) => {
	const { textColor } = props;


	return (
		<Container>
		  <div
				style={{ color: textColor }}
				className={ styles.homepageWrapper }>
				<div className={ styles.presentationWrapper }>
					<Col grid="lg-8 offset-lg-2">
						<div className={ styles.presentationLeft }>
						  <img
								className="homeImgAnim"
								src={ HomepageLogo }
								alt="Flawwless ui Logo"/>
							<h1
								style={{ color: textColor }}
								className={ `${ styles.homepageLogoName } homeTitleAnim` }>Flawwwless ui</h1>
							<p
								className="homeTextAnim"
								style={{ color: textColor }}>Building a React.js components library to help you to create applications that your users will love.</p>

							<div className={ `${ styles.presentationBtns } homeBtnAnim` }>
								<Link to="/documentation/intro">
									<Button
										style={{ margin: "5px 10px" }}
										type="primary">Dive into documentation</Button>
								</Link>

								<GithubButton />
							</div>
						</div>
					</Col>
				</div>

				<Newsletter />

			  <Tutorial />

			  <Details />


				<div className={ styles.docButton }>
					<Link to="/documentation/intro"><Button type="primary">Dive into documentation</Button></Link>
				</div>


			</div>
		</Container>
	)
}


export default Homepage;
