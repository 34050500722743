import React, { Component } from "react";
import { Drawer, Button } from "flwww";

class ComponentsToRender extends Component {
  state = {
    drawerIsVisible: false
  }

  toggleDrawer = () => {
    this.setState(prevState => ({ drawerIsVisible: !prevState.drawerIsVisible }))
  }

  render(){
    return (
      <div>
        <Button type="primary" onClick={ this.toggleDrawer }>Show the drawer</Button>

        <Drawer
          showDrawer={ this.state.drawerIsVisible }
          toggleDrawer={ this.toggleDrawer }>
          <h3>I'm a drawer!</h3>
          <p>You can put everything you want inside easily!</p>
        </Drawer>
      </div>
    )
  }
}

export default ComponentsToRender;
