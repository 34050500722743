import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "color",
		Description: <span>Specify the color of the Tag. The default color is the color stored inside the <code>primaryColor</code> (<code>#006eff</code>)</span>,
		Type: "String",
		Default: "#006eff",
	},
	{
		Property: "style",
		Description: "Override the Tag styling.",
		Type: "Object",
		Default: "{}",
	},
	{
		Property: "className",
		Description: "Add a className to the Tag.",
		Type: "String",
		Default: `""`,
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
