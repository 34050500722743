import React from 'react';
import { Container, Tooltip } from "flwww";
import styles from "./Footer.module.scss";

const Footer = (props) => {

	return(
		<div className={ styles.footerWrapper }>
		  <Container full>
			  <div className={ styles.innerFooter }>
					<div className={ styles.footerLeft }>
					  <a
						  href="https://github.com/JulienRioux/flawwwless-ui"
							target="_blank"
							rel="noopenner noreferrer">Github</a>

						<a
							href="https://www.npmjs.com/package/flwww"
							target="_blank"
							rel="noopenner noreferrer">NPM</a>

						<a
							href="https://twitter.com/FlawwwlessU"
							target="_blank"
							rel="noopenner noreferrer">Twitter</a>

						<a
							href="https://www.facebook.com/flawwwlessui"
							target="_blank"
							rel="noopenner noreferrer">Facebook</a>

					</div>

					<div className={ styles.footerRight }>
						<a
						  target="_blank"
						  href="https://julien-rioux.web.app/"
							rel="noopenner noreferrer">Code with ❤️ by <span className={ styles.coderName }>Julien Rioux</span></a>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default Footer;
