export default `import React from "react";
import { Link } from "react-router-dom";
import { ArticleCard, Row, Col } from "flwww";

const MyComponent = () => {

	// This is a fake article dataset
	const articles = [
		{
			img: "https://images.unsplash.com/photo-1530447920184-b88c8872?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
			title: "The Shocking Revelation of AI.",
			category: "Artificial intelligence",
			author: "Nyle Webb",
			mainLink: "https://futurism.com/scientists-ai-inventor-patent",
			time: "5 min ago",
		},
		{
			img: "https://images.unsplash.com/photo-1532187643603-ba119ca4109e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
			title: "Scientists Are Trying to List AI as the Inventor on a New Patent",
			category: "Science and inventions",
			author: "Talha Chapman",
			time: "38 min ago",
		},
		{
			img: "https://images.unsplash.com/photo-1524487225818-c6acacb24d4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1526&q=80",
			title: "YouTube Accidentally Removed Robot Battle Videos for “Animal Cruelty”",
			category: "Science and inventions",
			author: "Waqas Vickers",
			time: "15 min ago",
		},
		{
			img: "https://images.unsplash.com/photo-1471289660181-7feae98d61ae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
			title: "Agriculture and health industries adopt blockchain technology.",
			category: "Health",
			author: "Jolie Guzman",
			time: "21 min ago",
		},
	];

	return(
		<div style={{ margin: "60px 0"}}>
			<Row>
			  {
					articles.map((article, i) => (
						<Col grid="lg-6">
							<Link to="/documentation/article-card#default">
								<ArticleCard
								  style={{ marginBottom: "25px" }}
								  img={ article.img }
									title={ article.title }
									category={ article.category }
									author={ article.author }
									time={ article.time }
								/>
							</Link>
						</Col>
					))
				}
			</Row>
		</div>
	)
};

export default MyComponent;`;
