export default `import React from "react";
import { Menu } from "flwww";
import { Link } from "react-router-dom";

const MyComponent = () => (
  <div>
    <Menu
      style={{ width: 250 }}
      selectedItem={ "home" }>

      <Menu.Item uniqueKey={ "home" }>
        <Link to="/">Home</Link>
      </Menu.Item>

      <Menu.Item uniqueKey={ "products" }>
        <Link to="/products">Products</Link>
      </Menu.Item>

      <Menu.Item uniqueKey={ "pricing" }>
        <Link to="/pricing">Pricing</Link>
      </Menu.Item>

      <Menu.Item uniqueKey={ "support" }>
        <Link to="/support">Support</Link>
      </Menu.Item>

      <Menu.Item uniqueKey={ "explore" }>
        <Link to="/explore">Explore</Link>
      </Menu.Item>

    </Menu>
  </div>
);

export default MyComponent;`;
