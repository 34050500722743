import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "title",
		Description: "Title of the statistic.",
		Type: "String",
		Default: `""`,
	},
	{
		Property: "value",
		Description: "Latest value (display in big).",
		Type: "Number",
		Default: "undefined",
	},
	{
		Property: "prevValue",
		Description: "Previous value.",
		Type: "Number",
		Default: "undefined",
	},
	{
		Property: "currency",
		Description: "Unit of measure of the values.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "valueClassName",
		Description: "Add a className to the value (display in big) to customize (i.e. to change font-size on different device width).",
		Type: "String",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
