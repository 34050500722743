import React from "react";
import { Button } from "flwww";
import LoadingBtnsToRender from "./LoadingBtnsToRender";

const buttonStyle = {
	marginRight: "10px",
	marginBottom: "10px",
}

const DefaultBtnsToRender = () => (
	<div>
		<Button
			style={ buttonStyle }
			onClick={ () => alert("Button clicked!") }>Default</Button>

		<Button
			style={ buttonStyle }
			type="primary">Primary</Button>

		<Button
			style={ buttonStyle }
			type="success">Success</Button>

		<Button
			style={ buttonStyle }
			type="danger">Danger</Button>

		<Button
			style={ buttonStyle }
			colors={{ mainColor: "#e056fd", secondColor: "#fff" }}>Custom</Button>
	</div>
);

const RoundedBtnsToRender = () => (
	<div>
		<Button
			style={ buttonStyle }
			onClick={ () => alert("Button clicked!") }
			round>Default</Button>

		<Button
			style={ buttonStyle }
			type="primary"
			round>Primary</Button>

		<Button
			style={ buttonStyle }
			type="success"
			round>Success</Button>

		<Button
			style={ buttonStyle }
			type="danger"
			round>Danger</Button>

		<Button
			style={ buttonStyle }
			colors={{ mainColor: "#55E6C1", secondColor: "#000" }}
			round>Custom</Button>
	</div>
);

const OutlinedBtnsToRender = () => (
	<div>
		<Button
			style={ buttonStyle }
			outlined>Default</Button>
		<Button
			style={ buttonStyle }
			type="primary"
			outlined>Primary</Button>
		<Button
			style={ buttonStyle }
			type="success"
			outlined round >Success</Button>
		<Button
			style={ buttonStyle }
			type="danger"
			outlined round>Danger</Button>
		<Button
			style={ buttonStyle }
			outlined round
			colors={{ mainColor: "#000", secondColor: "#fff" }}>Custom</Button>
	</div>
);

const loadingBtnsToRender = (
	1
)

export { DefaultBtnsToRender, RoundedBtnsToRender, OutlinedBtnsToRender, LoadingBtnsToRender };
