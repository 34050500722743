import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
	Card,
	Button,
	Col,
	Input,
	Avatar,
	Statistic,
	ArticleCard,
	Rating,
	Badge
} from "flwww";
import styles from "./RollingFeatures.module.scss";
import FeaturesList from "./FeaturesList";

class RollingFeatures extends Component {

	render(){
		return (
			<Col grid="md-5">
				<Link
					className={ styles.cardLink }
					to="/documentation/intro">
				  <div style={{ position: "relative" }}>
					  <div className={ styles.whiteTopShaddow }></div>

						<div className={ styles.rollingWrapper }>
							<div className={ styles.rollingDiv }>
							  <FeaturesList />
							</div>

							<div className={ styles.rollingDiv2 }>
								<FeaturesList />
							</div>
						</div>

						<div className={ styles.whiteBottomShaddow }></div>
					</div>
				</Link>
			</Col>
		)
	}
}

export default RollingFeatures;
