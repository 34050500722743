import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "title",
		Description: "Add a title to your card.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "padding",
		Description: `Override the default padding of the card.`,
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "style",
		Description: `Override the card style (e.g. { border: "none" }).`,
		Type: "Object",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
