const codeString1 = `import React from "react";
import { Tooltip } from "flwww";

const MyComponent = (props) => {

  return (
		<div>
			<Tooltip text="I'm a default tooltip!">Hover me!</Tooltip>
		</div>
  )
}

export default MyComponent;`;


const codeString2 = `import React from "react";
import { Tooltip } from "flwww";

const MyComponent = (props) => {

  return (
		<div>
			<Tooltip
				position="bottom"
				text="I'm a bottom tooltip!">Hover me!</Tooltip>
		</div>
  )
}

export default MyComponent;`;


const codeString3 = `import React from "react";
import { Tooltip } from "flwww";

const MyComponent = (props) => {

  return (
		<div>
			<Tooltip
				dark
				text="I'm a dark tooltip!">Hover me!</Tooltip>
		</div>
  )
}

export default MyComponent;`;


export { codeString1, codeString2, codeString3 };
