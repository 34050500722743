import React, { Component } from "react";
import { Menu, Tag } from "flwww";
import { Link, withRouter } from "react-router-dom";
import styles from "./ComponentsMenu.module.scss";


class ComponentsMenu extends Component {

	render(){

		const currentComponent = this.props.history.location.pathname.split("/")[2];

		const { isDark } = this.props;

		return (
			<div className={ styles.componentsMenu }>
				<Menu
					style={{ width: "250px", marginLeft: "-16px", border: "0", backgroundColor: "#0000" }}
					selectedItem={ currentComponent }>

					<Menu.Item
						uniqueKey={ "intro" }>
						<Link to="/documentation/intro">Intro</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "button" }>
						<Link to="/documentation/button">
							Button
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "layout" }>
						<Link to="/documentation/layout">Layout</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "icon" }>
						<Link to="/documentation/icon">
							Icon
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "message" }>
						<Link to="/documentation/message">Message</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "checkbox" }>
						<Link to="/documentation/checkbox">
							Checkbox
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "input" }>
						<Link to="/documentation/input">
							Input
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "modal" }>
						<Link to="/documentation/modal">Modal</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "avatar" }>
						<Link to="/documentation/avatar">
							Avatar
						</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "badge" }>
						<Link to="/documentation/badge">
							Badge
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "menu" }>
						<Link to="/documentation/menu">Menu</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "drawer" }>
						<Link to="/documentation/drawer">Drawer</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "tag" }>
						<Link to="/documentation/tag">
							Tag
						</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "rating" }>
						<Link to="/documentation/rating">
							Rating
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "table" }>
						<Link to="/documentation/table">
							Table
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "top-loader" }>
						<Link to="/documentation/top-loader">
							TopLoader
						</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "progress" }>
					  <Link to="/documentation/progress">
							Progress
						</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "radial-progress" }>
						<Link to="/documentation/radial-progress">
							Radial Progress
						</Link>
					</Menu.Item>

					<Menu.Item
						uniqueKey={ "tooltip" }>
						<Link to="/documentation/tooltip">
							Tooltip
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "card" }>
						<Link to="/documentation/card">Card</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "switch" }>
						<Link to="/documentation/switch">
							Switch
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "dropdown" }>
						<Link to="/documentation/dropdown">
							Dropdown
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "article-card" }>
						<Link to="/documentation/article-card">
							ArticleCard
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "statistic" }>
						<Link to="/documentation/statistic">
							Statistic
						</Link>
					</Menu.Item>


					<Menu.Item
						uniqueKey={ "theme-provider" }>
						<Link to="/documentation/theme-provider">Theme Provider</Link>
					</Menu.Item>


				</Menu>
			</div>
		)
	}
}

export default withRouter(ComponentsMenu);
