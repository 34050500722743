import React, { Component } from "react";
import { Input, TextArea, Col, Row, Checkbox } from "flwww";


const ComponentsToRender1 = (props) => {

  return (
    <Row>
      <Col grid="lg-6">

				<Input placeholder="Simple input" />

			</Col>
    </Row>
  )
};


const ComponentsToRender2 = (props) => {

  return (
		<Row>
      <Col grid="lg-6">

				<Input
		      icon="search"
		      placeholder="Search something"
		      type="search" />

			</Col>
    </Row>
  )
}

class ComponentsToRender3 extends Component {
	state={
		authErrorCheckbox: true,
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

  render(){
		return (
			<Row>
	      <Col grid="lg-6">

					<div style={{ margin: "10px 0 20px" }}>
						<Checkbox
							name="authErrorCheckbox"
							checked={ this.state.authErrorCheckbox }
							onChange={ this.handleChange }>Click to change the error props.</Checkbox>
					</div>

					<Input
						error={ this.state.authErrorCheckbox }
						placeholder="Simple form error handling" />

				</Col>
	    </Row>
	  )
	}
}

const ComponentsToRender4 = (props) => {

  return (
		<Row>
      <Col grid="lg-6">

				<Input
		      style={{ marginBottom: ".7rem" }}
		      placeholder="This is a text input" />

		    <Input
		      style={{ marginBottom: ".7rem" }}
		      type="number"
		      placeholder="This is a number input" />

		    <Input
		      style={{ marginBottom: ".7rem" }}
		      placeholder="This is a search input"
		      type="search" />

		    <Input
		      style={{ marginBottom: ".7rem" }}
		      placeholder="This is a password input"
		      type="password" />

		    <TextArea
		      rows={ 4 }
		      placeholder="This is a textArea" />

			</Col>
    </Row>
  )
}


const ComponentsToRender5 = (props) => {

  return (
		<Row>
      <Col grid="lg-6">

				<Input
		      style={{ fontSize: "2rem", marginBottom: ".7rem" }}
		      icon="user"
		      placeholder="username" />

		    <Input
		      style={{ borderRadius: "1rem", color: "blue" }}
		      placeholder="This is a rounded input" />

			</Col>
    </Row>
  )
}

export { ComponentsToRender1, ComponentsToRender2, ComponentsToRender3, ComponentsToRender4, ComponentsToRender5 };
