import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, message, Button } from "flwww";

const ComponentsToRender = () => {

	const elementList = [
		<button onClick={ () => message("Button clicked", "success") }>Send a message!</button>,
		<a target="_blank" rel="noopener noreferrer" href="http://flawwwless.com">Go to flawwwless.com</a>,
		<Link to="/documentation/intro">Go to intro</Link>,
	];

	return(
	  <div style={{ margin: "40px 0"}}>

			<Dropdown elementList={ elementList }>

				<Button type="primary">menu</Button>

			</Dropdown>

	  </div>
	)
};

export default ComponentsToRender;
