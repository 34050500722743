import React from 'react';
import ShowCode from "./ShowCode";
import styles from "./DocBlock.module.scss";
import { Link, withRouter } from "react-router-dom";

const DocBlock = (props) => {

	const componentsoRender = props.children ;

	const currentPath = props.history.location.pathname;

	return (
		<section id={ props.sectionName }>
			<div style={{ marginBottom: "4rem" }}>
			  <p>
					<a
						href={ `#${ props.sectionName }`}
						className={ styles.sectionStart }>↳</a>
					{ props.subTitle }</p>

			  <div>
					{ props.children }
				</div>

				{
					props.codeString && (
						<ShowCode codeString={ props.codeString } />
					)
				}
			</div>
		</section>
	)
}

export default withRouter(DocBlock);
