import React, { Component } from 'react';
import { Button } from "flwww";
import axios from "axios";

class GithubButton extends Component {
	state = {
		starCount: undefined,
	}

	componentDidMount(){
		const url = "https://api.github.com/repos/JulienRioux/flawwwless-ui";
		const self = this;
		axios.get(url)
		  .then(function (response) {
		    // handle success
		    const starCount = response.data.stargazers_count;
				self.setState({ starCount });
		  })
		  .catch(function (error) {
		    // handle error
		    console.log(error);
		  })
	}

	render(){
		const { starCount } = this.state;

		return (
			<a
				href="https://github.com/JulienRioux/flawwwless-ui"
				target="_blank"
				rel="noopener noreferrer">
				<Button
					style={{ margin: "5px 10px" }}
					outlined
					type="primary">
					<span>Github </span>
					{ starCount && <small>({ starCount } stars)</small> }
				</Button>
			</a>
		)
	}
}

export default GithubButton;
