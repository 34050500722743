const codeString1 = `import React, { Component } from "react";
import { Rating, message } from "flwww";

class MyComponent extends Component {
	state={
			rating: null,
		}

		handleRatingChange = (rating) => {
			// Alert the rating ;)
			message(\`Rating: $\{ rating }/5\`, "success");
			this.setState({ rating });
		}

		render(){

			return(
				<Rating
					rating={ this.state.rating }
					handleRatingChange={ this.handleRatingChange } />
			)
		}
}

export default MyComponent;`;


const codeString2 = `import React, { Component } from "react";
import { Rating } from "flwww";

class MyComponent extends Component {

	render(){

		return(
			<Rating
				color={ "#ffdd00" }
				size={ "50px" }
				rating={ 4 }
				isStatic
			/>
		)
	}
}

export default MyComponent;`;





export { codeString1, codeString2 };
