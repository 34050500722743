import React from 'react';
import styles from "./TopLoader.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import { codeString1, codeString2 } from "./CodeString";
import { ComponentsToRender1, ComponentsToRender2 } from "./ComponentsToRender";
import apiData from "./apiData";
import CarbonAds from "../../../CarbonAds";

const TopLoader = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">TopLoader</h1>

				<DocBlock
					sectionName="default"
					subTitle="Easy implementation of a fixed-top loader."
					codeString={ codeString1 }>
					<ComponentsToRender1 />
				</DocBlock>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default TopLoader;
