import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class ScrollTop extends Component {

	componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
			window.scrollTo(0, 0);
			const scrollableDoc = document.querySelector("#scrollableDoc");
			scrollableDoc.scrollTo(0, 0);
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

	render(){
		return (
			this.props.children
		)
	}
}

export default withRouter(ScrollTop);
