import React from "react";
import { Icon } from "flwww";

const ComponentsToRender = () => (
	<div style={{ fontSize: "35px" }}>
		<Icon type="thumbUp" />

		<Icon type="clap" color="#01b875" />

		<Icon type="globe" isSpinning />

		<Icon type="home" size="140px" color="#006EFF" />
	</div>
);

export default ComponentsToRender;
