import React from 'react';
import styles from "./Message.module.scss";

import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import CodeString from "./CodeString";
import ComponentsToRender from "./ComponentsToRender";
import apiData from "./apiData";

import message from "../../../../images/messageExample/message.png";

import CarbonAds from "../../../CarbonAds";

const Message = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">Message</h1>

			<DocBlock
				sectionName="default"
				subTitle="The easy way to send message to the user."
				codeString={ CodeString }>
				<ComponentsToRender />
			</DocBlock>


			<h3 style={{ margin: 0, fontSize: "2rem" }}>API</h3>


		  <img
				style={{ height: "45px", margin: "30px 3px" }}
				src={ message }
				alt="message([content], [type], [duration])"/>

			<ApiTable
				apiData={ apiData }
				noTitle={ true } />
		</div>
	)
}

export default Message;
