import React, { Component } from "react";
import { Checkbox } from "flwww";

class ComponentsToRender extends Component {
	state = {
		checkbox: true,
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

  render(){

    return (
      <div>
				<Checkbox
				  name="checkbox"
				  onChange={ this.handleChange }
				  checked={ this.state.checkbox }>Checkbox</Checkbox>
      </div>
    )
  }
}

export default ComponentsToRender;
