import React from 'react';
import { Button, Row, Col } from "flwww";
import styles from "../Homepage.module.scss";
import HomepageImage1 from "../../../images/SVG/HomepageImage1.svg";

const Details = (props) => {

	return (
		<div className={ styles.mainDetailsWrapper }>
		  <Row>

				<Col grid="lg-3 6">
					<div className={ styles.detailWrapper  }>
						<h6 className={ styles.detailTitle }>Free</h6>
						<p>Flawwwless ui is Open source</p>
					</div>
				</Col>

				<Col grid="lg-3 6">
					<div className={ styles.detailWrapper  }>
						<h6 className={ styles.detailTitle }>23+</h6>
						<p>Awesome Components ready to use</p>
					</div>
				</Col>

				<Col grid="lg-3 6">
					<div className={ styles.detailWrapper  }>
						<h6 className={ styles.detailTitle }>{ `</>` }</h6>
						<p>Clear and Simple documentation</p>
					</div>
				</Col>

				<Col grid="lg-3 6">
					<div className={ styles.detailWrapper  }>
						<h6 className={ styles.detailTitle }>Custom</h6>
						<p>Customize your components effortlessly</p>
					</div>
				</Col>



			</Row>
		</div>
	)
}

export default Details;
