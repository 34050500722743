import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "text",
		Description: "This is the text you want to place inside the Tooltip.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "position",
		Description: <span>If set to <code>position="bottom"</code>, the tooltip will be placed underneath the element you want to add a tooltip.</span>,
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "dark",
		Description: "Change to a dark tooltip.",
		Type: "Boolean",
		Default: "false",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
