import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "columns",
		Description: "An array that represent the key names of your dataset.",
		Type: "Array of String",
		Default: "undefined",
	},
	{
		Property: "rows",
		Description: "An array of object that represent your dataset. Pay attention to have the same key name as the one in your columns array!",
		Type: "Array of Object",
		Default: "undefined",
	},
	{
		Property: "bordered",
		Description: `Whether the table have a border or not.`,
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "style",
		Description: `Override the drawer style (e.g. { borderRadius: "0px" }).`,
		Type: "Object",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
