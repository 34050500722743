import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const containerRows = [
	{
		Property: "full",
		Description: "Whether the Container should take the full width of the page or not.",
		Type: "Boolean",
		Default: "false",
	}
];

const apiContainerData = {
	columns: columns,
	rows: containerRows,
};


const colRows = [
	{
		Property: "grid",
		Description: "A string that represent the layouts of the column. If using multiple layouts (e.g. breakpoints and/or offsets), separate them with space.",
		Type: "String",
		Default: "undefined",
	}
];

const apiColData = {
	columns: columns,
	rows: colRows,
};



export { apiContainerData, apiColData };
