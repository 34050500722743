const codeString1 = `import React from "react";
import { Card } from "flwww";

const MyComponent = (props) => {

  return (
    <div style={{ width: "500px" }}>

			<Card title="Title of the card">
				<p>Put anything you want inside!</p>
			</Card>

    </div>
  )
}

export default MyComponent;`;


const codeString2 = `import React from "react";
import { Card } from "flwww";

const MyComponent = (props) => {

  return (
    <div style={{ width: "500px" }}>

			<Card
				style={{ boxShadow: "0 10px 20px #0003", border: "none" }}>
					<h3 style={{ marginTop: ".5rem" }}>A card with shadow</h3>
					<p>You can put whatever you want inside those cards and customize it using style props.</p>

				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button round type="primary">Continue</Button>
				</div>
			</Card>

    </div>
  )
}

export default MyComponent;`;


export { codeString1, codeString2 };
