import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "isVisible",
		Description: "Whether the modal is visible or not.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "toggleModal",
		Description: "Specify a function that control the modal visibility state.",
		Type: "Function",
		Default: "undefined",
	},
	{
		Property: "title",
		Description: "Add a title to your modal.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "width",
		Description: "Specify the width of the modal.",
		Type: "String",
		Default: "600px",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
