import React from "react";
import { Statistic, Col, Row } from "flwww";

const ComponentsToRender = () => {

	return(
		<div style={{ margin: "60px 0" }}>
			<Row>

				<Col grid="md-6" style={{ marginBottom: "60px" }}>
					<Statistic
						title="Monthly subscriptions"
						value={ 1523 }
						prevValue={ 358 }
						currency="Users" />
				</Col>

				<Col grid="md-6" style={{ marginBottom: "60px" }}>
					<Statistic
						title="Profit YOY"
						value={ 2.342 }
						prevValue={ 3.352 }
						currency="M$" />
				</Col>

				<Col grid="md-6" style={{ marginBottom: "60px" }}>
					<Statistic
						title="Bitcoin / EUR"
						value={ 9099.76 }
						prevValue={ 9129.14 }
						currency="€" />
				</Col>

				<Col grid="md-6" style={{ marginBottom: "60px" }}>
					<Statistic
						title="Tesla Inc"
						value={ 256.59 }
						prevValue={ 235.20 }
						currency="usd"
					/>
				</Col>

			</Row>
		</div>
	)
};

export default ComponentsToRender;
