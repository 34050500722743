export default `import React, { Component } from "react";
import { Switch } from "flwww";

class MyComponent extends Component {
	state = {
		switchVal: true,
	}


	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	render(){

		return (
			<div style={{ padding: "1rem 0" }}>
				<Switch
					name="switchVal"
					onChange={ this.handleChange }
					checked={ this.state.switchVal }
				/>
			</div>
		)
	}
}

export default MyComponent;`;
