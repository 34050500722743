import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Switch, message, Button } from "flwww";

class ComponentsToRender extends Component {
	state = {
		switchVal: true,
	}


	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	render(){

		return (
			<div style={{ padding: "1rem 0" }}>
				<Switch
					name="switchVal"
					onChange={ this.handleChange }
					checked={ this.state.switchVal }
					// disabled={ true }
				/>
			</div>
		)
	}
}

export default ComponentsToRender;
