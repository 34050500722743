import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "colors",
		Description: `Customize your theme colors. You only have to specified the colors that you want to change to override the default theme colors. (e.g. { primaryColor: "#720dd8", dangerColor: "#b71540", }).`,
		Type: "Object",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
