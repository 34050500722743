import React from 'react';
import styles from "./Tag.module.scss";
import DocBlock from "../DocBlock";
import ApiTable from "../ApiTable";
import { codeString1, codeString2 } from "./CodeString";
import { ComponentsToRender1, ComponentsToRender2 } from "./ComponentsToRender";
import apiData from "./apiData";
import CarbonAds from "../../../CarbonAds";

const Tag = (props) => {

	return (
		<div className="fadeIn">

			<CarbonAds />

			<h1 className="componentTitle">Tag</h1>

				<DocBlock
					sectionName="default"
					subTitle="Categorize and label using tags."
					codeString={ codeString1 }>
					<ComponentsToRender1 />
				</DocBlock>

				<DocBlock
					sectionName="custom"
					subTitle="Customize your tags."
					codeString={ codeString2 }>
					<ComponentsToRender2 />
				</DocBlock>

				<ApiTable
					apiData={ apiData } />
		</div>
	)
}

export default Tag;
