import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "type",
		Description: <span>The type of Button (<code>Primary</code>, <code>Success</code> or <code>Danger</code>). If not specified, it will be the default color.</span>,
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "outlined",
		Description: "Outline the Button.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "onClick",
		Description: "Specify what to do on Button click.",
		Type: "Function",
		Default: "undefined",
	},
	{
		Property: "colors",
		Description: <span>Specify custom colors for the button (<code>{ `{ mainColor: "#e056fd", secondColor: "#fff" }` }</code>).</span>,
		Type: "Object",
		Default: "undefined",
	},
	{
		Property: "round",
		Description: "Round the corner of the Button.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "loading",
		Description: "Set the loading state of the Button.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "disabled",
		Description: "Disable the Button from click.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "style",
		Description: <span>Override the Button style (e.g. <code>{`{  borderRadius: "0px"  }`}</code>).</span>,
		Type: "Object",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
