import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "selectedItem",
		Description: "Keys of the currently selected menu item.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "style",
		Description: `Override the menu style (e.g. { borderRight: "none" }).`,
		Type: "Object",
		Default: "undefined",
	},
];

const apiData = {
	columns,
	rows,
}

export default apiData;
