import React, { Component } from "react";
import { Table } from "flwww";

const columns = [
	"Property",
	"Description",
	"Type",
	"Default"
];

const rows = [
	{
		Property: "checked",
		Description: "Specifies whether the checkbox is selected.",
		Type: "Boolean",
		Default: "false",
	},
	{
		Property: "onChange",
		Description: "The callback function that is triggered when the state changes.",
		Type: "Function",
		Default: "undefined",
	},
	{
		Property: "name",
		Description: "The name property of the checkbox.",
		Type: "String",
		Default: "undefined",
	},
	{
		Property: "disabled",
		Description: "Disable checkbox.",
		Type: "Boolean",
		Default: "false",
	},


];

const apiData = {
	columns,
	rows,
}

export default apiData;
